<template>
	<prac-test :test=test>
		<span slot="diagram">
			<img src="/svg/pages/design-patterns/design-patterns-overview.svg"
				alt="Diagram of The Gang of Four design patterns"/>
		</span>
	</prac-test>
</template>
<script>
import { PracTest } from "@/components";
export default {
  name: "design-patterns",
  components: {
    PracTest
  },
  data() {
    return {
      test: {
        date: "June 2019",
        title: "Design Patterns - Advanced",
        subtitle:
          "Learn more about design patterns to develop better programs.",
        description:
          "Online flashcards about advanced details of the most important design patterns in object oriented designs.",
        bg: "/img/tests/inf/se/design-patterns.jpg",
        author: "Software Engineering",
        references: "",
        items: [
          {
            front: "<h4>What is the purpose of design patterns?</h4>",
            back:
              "Design patterns provide a well-proven and elegant solution to general-purpose problems in object-oriented software design. They serve as a common communication vocabulary among developers."
          }
        ]
      }
    };
  }
};
</script>
